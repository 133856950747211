import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaPaste } from "react-icons/fa";

const ImportWallet = ({
  setSelected3,
  setPass,
  setPrograss,
  ref,
  handlePaste,
  submitNewAddress,
  pass,
}) => {
  return (
    <div className="p-2">
      <>
        <div className="flex justify-between items-center space-x-4 ">
          <p
            onClick={() => {
              setSelected3((prevState) => !prevState);
              setPrograss((prevState) => prevState - 20);
            }}
            className="cursor-pointer flex justify-between "
          >
            <AiOutlineArrowLeft className="bg-black rounded-2xl p-2 text-3xl text-white" />
          </p>
          <p className="font-[500] text-black lg:text-2xl text-xl">
            Mnemonic Or Private Key
          </p>
        </div>

        <p className="my-3 font-[500] lg:text-md text-xs text-black">
          Restore an exsiting wallet with your 12 or 24 mnemonic words or your
          private key.
        </p>
        <div className="relative">
          <textarea
            onChange={(e) => setPass(e.target.value)}
            value={pass}
            ref={ref}
            className="bg-transparent border rounded-md outline-0 w-full h-40 p-3 text-black"
            placeholder="words separated by single space"
          />
          <FaPaste
            className="absolute bottom-2 text-black right-0 shadow-xl text-lg ursor-pointer"
            onClick={(e) => {
              handlePaste(setPass);
            }}
          />
        </div>

        <br />
        <div className="flex flex-col items-center ">
          <button
            onClick={() => submitNewAddress()}
            disabled={!pass}
            className={
              pass
                ? "bg-black text-white w-full px-5 py-2 rounded-md hover:opacity-80 shadhow-xl font-[600] mb-1 mt-2 transition duration-300 ease-in-out outline-0"
                : "bg-black text-white opacity-20 w-full px-5 py-2 rounded-md  shadhow-xl font-[600] mb-1 mt-2 transition duration-300 ease-in-out outline-0"
            }
          >
            Connect Wallet
          </button>
        </div>
      </>
    </div>
  );
};

export default ImportWallet;